<template>
    <div class="lyrics-section">
        <h1> Texte </h1>
        <div class="lyrics lyrics-heading">
            <button class="skip-btn" @click="previousLyricsBtnClicked()"> <span class="material-symbols-outlined">
                    arrow_back_ios
                </span></button>
            <div class="lyrics-title ">
                <h2>{{ allLyrics[$store.state.currentLyricsIdx].title }}</h2>
            </div>
            <button class="skip-btn" @click="nextLyricsBtnClicked()"> <span class="material-symbols-outlined">
                    arrow_forward_ios
                </span></button>
        </div>
        <perfect-scrollbar id="lyricsScrollSection">
            <p class="lyrics lyrics-text">
                <span v-for="(line, lineNumber) of allLyrics[$store.state.currentLyricsIdx].lyrics.split('\n')"
                    v-bind:key="lineNumber">
                    {{ line }}<br /></span>
            </p>
        </perfect-scrollbar>
    </div>
</template>

<script>
import {
    allLyrics
} from "../data/lyrics.js";
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import store from '../store/store.js'
function nextLyrics() {
    document.getElementById("lyricsScrollSection").scrollTop = 0;
    store.commit('incrementCurrentLyricsIdx')

}
function previousLyrics() {
    document.getElementById("lyricsScrollSection").scrollTop = 0;
    store.commit('decrementCurrentLyricsIdx')

}
export default {
    components: { PerfectScrollbar },
    name: 'LyricsSection',
    data() {
        return {
            innerWidth: window.innerWidth,
            allLyrics: allLyrics,
        };
    },
    props: {
    },
    mounted() {
        window.addEventListener("resize", this.screenSizeChanged);
    },
    unmounted() {
        window.removeEventListener("resize", this.screenSizeChanged);
    },
    methods: {
        screenSizeChanged() {
            this.innerWidth = window.innerWidth
        },
        previousLyricsBtnClicked() {
            previousLyrics()
        },
        nextLyricsBtnClicked() {
            nextLyrics()
        },
    }
}
</script>















<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css"/>
<style scoped>
.lyrics-section {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 120px;
    width: min(80vw, 600px);
}

.lyrics {
    text-align: left;
}

.lyrics-text {
    /* height: 500px; */
    padding-top: 10px;
    padding-left: 2px;
    /*     overflow-y: scroll; */
    color: white;
    background-color: black;
    font-size: large;
}

.skip-btn {
    color: white;
    background-color: black;
    border: none;
}

.skip-btn:active {
    background-color: black;
}

.lyrics-title {
    vertical-align: middle;
    text-transform: uppercase;
    color: white;
    text-align: center;
}

.lyrics-heading {
    justify-content: center;
    display: flex;
}

.skip-btn {
    cursor: pointer;
}

.ps {
    height: 80vh;
}
</style>
