<template>
    <div class="intro-text-wrapper">
        <p class="paragraph-friso" :style="{ textTransform: 'none' }">Don Friso braucht nur ein
            Soundsystem
            & einen Quadratmeter Platz und zieht den ganzen Raum in den Bann.
        </p>

    </div>
</template>

<script>
export default {
    name: 'IntroText',
    data() {
        return {
            innerWidth: window.innerWidth,
            menuOpen: false
        };
    },
    props: {
    },
    mounted() {
        window.addEventListener("resize", this.screenSizeChanged);
    },
    unmounted() {
        window.removeEventListener("resize", this.screenSizeChanged);
    },
    methods: {
        screenSizeChanged() {
            this.innerWidth = window.innerWidth
        }
    }
}
</script>

<style scoped>
.intro-text-wrapper {
    width: min(80vw, 400px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 120px;
    margin-bottom: 120px;
}
</style>
