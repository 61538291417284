<template>
  <global-events v-if="$store.state.showPlayer" @keydown.prevent.space.exact="stopPlayerBySpaceBar()" />
  <HeroHeader />
  <SocialButtons />
  <DescriptionText />
  <FrisoVideo link="https://www.youtube.com/embed/VxTBaD1x5Po?autoplay=1&mute=1&start=30" />
  <IntroText />
  <FrisoVideo link="https://www.youtube.com/embed/eaZyzOH9YL8?autoplay=1&mute=1&start=30" />
  <h1 id="musik">Musik</h1>
  <MusicText />
  <AudioPlayer v-if="false" />
  <AudioSectionLinkOnly title="Läbe & Tod EP" year="2024" link="https://tr.ee/gvqoUHdcaC" :songs="allSongs.slice(6, 11)"
    :titleImage="laebeOndTodImage" />
  <AudioSectionLinkOnly title="Gedanke Architektur Single" year="2022" link="https://tr.ee/oUrd1tg06V"
    :songs="allSongs.slice(5, 6)" :titleImage="gedankeArchitekturImage" />
  <AudioSectionLinkOnly title="Achterbahn EP" year="2022" link="https://tr.ee/YA9SStbFqJ" :songs="allSongs.slice(0, 5)"
    :titleImage="achterbahnImage" />
  <!-- <AudioSection title="Läbe & Tod EP" year="2024" :songs="allSongs.slice(6, 11)" :titleImage="laebeOndTodImage" />
  <AudioSection title="Gedanke Architektur Single" year="2022" :songs="allSongs.slice(5, 6)"
    :titleImage="gedankeArchitekturImage" />
  <AudioSection title="Achterbahn EP" year="2022" :songs="allSongs.slice(0, 5)" :titleImage="achterbahnImage" /> -->
  <ListenButtons />
  <LyricsSection id="texte" />
  <FrisoVideo link="https://www.youtube.com/embed/oe_kHAcHLYE?autoplay=1&mute=1&start=0" />
  <LiveSection id="live" />
  <!--   <h1 id="kontakt">Kontakt</h1>-->
  <KontaktForm id="kontakt" />
  <!--   <KontaktSection /> -->
  <FooterSection />
  <AudioPlayerCustom />
</template>

<script>
import ListenButtons from './components/ListenButtons.vue'
import { GlobalEvents } from 'vue-global-events'
import HeroHeader from './components/HeroHeader.vue'
import SocialButtons from './components/SocialButtons.vue';
import IntroText from './components/IntroText.vue'
import DescriptionText from './components/DescriptionText.vue';
import MusicText from './components/MusicText.vue';
import AudioPlayer from './components/AudioPlayer.vue'
/* import AudioSection from './components/AudioSection.vue' */
import AudioSectionLinkOnly from './components/AudioSectionLinkOnly.vue';
import AudioPlayerCustom from './components/AudioPlayerCustom.vue'
import KontaktForm from './components/KontaktForm.vue'
/* import KontaktSection from './components/KontaktSection.vue' */
import LyricsSection from './components/LyricsSection.vue'
import LiveSection from './components/LiveSection.vue'
import FrisoVideo from './components/FrisoVideo.vue'
import FooterSection from './components/FooterSection.vue'
import {
  allSongs
} from "./data/data.js";
export default {
  name: 'App',
  components: {
    GlobalEvents,
    AudioPlayer,
    AudioPlayerCustom,
    HeroHeader,
    SocialButtons,
    IntroText,
    DescriptionText,
    MusicText,
    /* AudioSection, */
    AudioSectionLinkOnly,
    ListenButtons,
    KontaktForm,
    /*  KontaktSection, */
    LyricsSection,
    LiveSection,
    FrisoVideo,
    FooterSection,
  },
  data() {
    return {
      allSongs: allSongs,
      achterbahnImage: require("@/assets/graphics/covers/Don_Friso_Achterbahn_Cover.png"),
      gedankeArchitekturImage: require("@/assets/graphics/covers/Don_Friso_GedankeArchitektur_Cover.png"),
      laebeOndTodImage: require("@/assets/graphics/covers/Don_Friso_Läbe_&_Tod_Cover.png"),
      laebeImage: require("@/assets/graphics/covers/Donfriso_Laebe_Single_Cover_Farbig.png"),
      ondImage: require("@/assets/graphics/covers/Donfriso_singlecover2_ondZeichenGroesser_v005_farbig.png"),
      vertroueImage: require("@/assets/graphics/covers/241701_Donfriso_singelcover_Vertroue_Losloh.png")
    };
  },
  methods: {
    stopPlayerBySpaceBar() {
      if (this.$store.state.playerIsPlaying) {
        document.getElementById("av-tag").pause()
        this.$store.commit('stopPlaying')
      }
      else if (this.$store.state.showPlayer) {
        document.getElementById("av-tag").play()
        this.$store.commit('startPlaying')
      }
    }
  },
}
</script>

<style>
@import './assets/styles/variables.css';
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap');

#app {
  font-family: 'Montserrat', "Helvetica", "Roboto", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
  width: 100%;
}
</style>./components/KontaktSection.vue
