<template>
    <div class="friso-video-wrapper">
        <iframe :width="560 * 1.5" :height="315 * 1.5" style="max-width: 100vw; max-height: calc(100vw/560*315)" :src=link
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
    </div>
</template>

<script>
export default {
    name: 'FrisoVideo',
    data() {
        return {
            innerWidth: window.innerWidth,
        };
    },
    props: {
        link: { required: true },
    },
    mounted() {
        window.addEventListener("resize", this.screenSizeChanged);
    },
    unmounted() {
        window.removeEventListener("resize", this.screenSizeChanged);
    },
    methods: {
        screenSizeChanged() {
            this.innerWidth = window.innerWidth
        }
    }
}
</script>

<style scoped></style>
