<template>
    <div id="header-hero-main" :class="headerImageIsLoaded ? 'not-hidden header-hero-main' : 'hidden header-hero-main'">
        <div>
            <img class="header-menu-side header-menu-logo" src="@/assets/graphics/don_friso_logo.png" />
        </div>
        <div class="header-menu-text-wrapper">
            <div v-if="innerWidth < 1100">
                <p class="header-menu-side header-menu-text">
                    <a @click="menuClicked()">MENU </a><br />
                </p>
                <div :class="!menuOpen ? 'mobile-menu mobile-menu-collapsed' : 'mobile-menu mobile-menu-expanded'">
                    <p class="header-menu-side header-menu-text paragraph-friso">
                        <a href="#musik">MUSIK </a><br />
                        <a href="#texte">TEXTE </a><br />
                        <a href="#live">LIVE </a><br />
                        <a href="#kontakt">KONTAKT </a><br />
                    </p>
                </div>
            </div>
            <p v-else class="header-menu-side header-menu-text paragraph-friso">
                <a href="#musik">MUSIK </a><br />
                <a href="#texte">TEXTE </a><br />
                <a href="#live">LIVE </a><br />
                <a href="#kontakt">KONTAKT </a><br />
            </p>
        </div>
        <div class="header-image">
            <img id="header-hero-img" @load="headerImageLoaded()" src="@/assets/img/Don_Friso_faded_min.png" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeroHeader',
    data() {
        return {
            innerWidth: window.innerWidth,
            menuOpen: false,
            headerImageIsLoaded: false,
        };
    },
    props: {
    },
    mounted() {
        window.addEventListener("resize", this.screenSizeChanged);
    },
    unmounted() {
        window.removeEventListener("resize", this.screenSizeChanged);
    },
    methods: {
        headerImageLoaded() {
            this.headerImageIsLoaded = true;
        },
        menuClicked() {
            this.menuOpen = !this.menuOpen;
        },
        screenSizeChanged() {

            this.innerWidth = window.innerWidth
        }
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
    color: white;
}

/* General */
.header-hero-main {
    /*  -webkit-transition: all 2s ease;
    -moz-transition: all 2s ease;
    -ms-transition: all 2s ease;
    -o-transition: all 2s ease; */
    transition: all 3s ease;
}

.not-hidden {
    opacity: 1;
}

.hidden {
    opacity: 0;
}

#header-hero-main {
    min-height: 100vh;
}

.mobile-menu {
    transition: max-height 1s ease-in-out, opacity 0.75s ease-in-out;
    overflow: hidden;
}

.mobile-menu-collapsed {
    max-height: 0px;
    opacity: 0;
}

.mobile-menu-expanded {
    max-height: 600px;
    opacity: 1;
}

.header-menu-text {
    color: var(--text-color);
    font-weight: medium;
    font-size: x-large;

}

.header-menu-text>a {
    cursor: pointer;
    transition: color 0.5s ease;
}

.header-menu-text>a:hover {
    color: var(--achterbahn-color);
    transition: color 0.5s ease;
}

#header-hero-img {

    /* this was the last one:  */
    mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0)); 
    filter: brightness(1.12); /* Increase brightness by 50% */
    /*     height: 100vh; */
    /*     -webkit-mask-image: -webkit-gradient(linear, top, bottom, from(rgba(0, 0, 0, 1))  30%, to(rgba(0, 0, 0, 0))); */
    
    /* linear-gradient(180deg, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0)); 
        radial-gradient(circle at 50% 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 188, 212, 0) 95%)
        https://angrytools.com/gradient/*/
    /*     
 radial-gradient(ellipse at top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 95%);*/
}

/* Mobile only */
#header-hero-img {
    width: min(90vw, 600px);
}

.header-menu-text-wrapper {
    text-align: center;
    display: inline-block;
    margin-bottom: 20px;
    transition: all 1s linear;
}

.header-menu-side {
    width: min(60vw, 300px);

}



.header-menu {
    display: flex;
    justify-content: space-between;
}

/* Desktop only */

@media only screen and (min-width: 1100px) {
    .header-menu-side {
        position: absolute;
    }

    .header-menu-logo {
        left: 0px;
    }

    .header-menu-text {
        right: 20px;
        text-align: right;
    }

    #header-hero-img {
        width: 600px;
    }

}
</style>
