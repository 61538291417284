<template>
    <div class="music-descr-text-wrapper">
        <p class="paragraph-friso" :style="{ textTransform: 'none', fontSize: 'medium', textAlign:'left',marginBottom:'60px' }">Die neue EP 'Läbe & Tod' fasst
            die
            Emotionen und Gedanken Don Frisos der letzten zwei Jahre in einem Medley von Rap, Rock und Open Music
            zusammen und möchte anregen, über (Tabu-) Themen wie Eigenliebe, toxische Beziehungen und Tod zu sprechen.
        </p>
        <p class="paragraph-friso" :style="{ textTransform: 'none', fontSize: 'medium', fontStyle:'italic',textAlign:'center' }">«Ech ha mini eiget Schönheit
            ewigs ned gseh <br>
            Ha gmeint, ech entspreche de Kriterie ned»
        </p>
    </div>
</template>

<script>
export default {
    name: 'MusicText',
    data() {
        return {
            innerWidth: window.innerWidth,
            menuOpen: false
        };
    },
    props: {
    },
    mounted() {
        window.addEventListener("resize", this.screenSizeChanged);
    },
    unmounted() {
        window.removeEventListener("resize", this.screenSizeChanged);
    },
    methods: {
        screenSizeChanged() {
            this.innerWidth = window.innerWidth
        }
    }
}
</script>

<style scoped>
.music-descr-text-wrapper {
    width: min(80vw, 800px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    margin-bottom: 80px;
    text-align: justify;
}
</style>
