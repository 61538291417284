<template>
    <div class="audio-section-wrapper">
        <!-- <h1>Musik</h1> -->
        <div class="audio-section-title paragraph-friso">
            <h2 class="paragraph-friso">{{ title }} <span class="year">{{ year }}</span></h2>
        </div>
        <div class="audio-section">
            <div class="audio-section-image">
                <img class="audio-cover-image" :src="titleImage" />
            </div>
            <div class="audio-section-songs">
                <div class="audio-section-row" v-for="song in songs" :key="song.title">
                    <a :href="link" :target="linkTarget">
                        <div class="audio-section-play-btn">
                            <img v-if="innerWidth < 1100" v-bind:class="{
                'social-button mobile-button': true,
                'btn-friso': allSongs[this.$store.state.currentSongIdx].title != song.title || !this.$store.state.playerIsPlaying,
                'btn-friso-hovered': allSongs[this.$store.state.currentSongIdx].title == song.title && this.$store.state.playerIsPlaying,
            }" src="@/assets/graphics/buttons/play_mobile.svg" />
                            <img v-else v-bind:class="{
                'social-button desktop-button': true,
                'btn-friso': allSongs[this.$store.state.currentSongIdx].title != song.title || !this.$store.state.playerIsPlaying,
                'btn-friso-hovered': allSongs[this.$store.state.currentSongIdx].title == song.title && this.$store.state.playerIsPlaying,
            }" src="@/assets/graphics/buttons/play_desktop.svg" />
                        </div>
                    </a>
                    <div class="song-names paragraph-friso">
                        <p class="paragraph-friso">{{ song.title }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    allSongs
} from "../data/data.js";
export default {
    name: 'AudioPlayer',
    data() {
        return {
            innerWidth: window.innerWidth,
            linkTarget: window.innerWidth > 768 ? '_blank' : '_self',
            menuOpen: false,
            allSongs: allSongs,
        };
    },
    props: {
        title: { required: true },
        year: { required: true },
        link: { required: true },
        songs: { required: true },
        titleImage: { required: true }
    },
    mounted() {
        window.addEventListener("resize", this.screenSizeChanged);
    },
    unmounted() {
        window.removeEventListener("resize", this.screenSizeChanged);
    },
    methods: {
        screenSizeChanged() {
            this.innerWidth = window.innerWidth
            this.linkTarget = window.innerWidth > 768 ? '_blank' : '_self';
        },
        startPlayerWithSong(song) {
            const idx = this.allSongs.findIndex(p => p.title == song.title);
            this.$store.commit('audioIsNotYetLoaded')
            this.$store.commit('setCurrentSongIdx', idx)
            this.$store.commit('startPlaying')
            this.$store.commit('openPlayer')
            document.getElementById("av-tag").load()
            document.getElementById("av-tag").play()
        }
    }
}
</script>

<style scoped>
.year {
    font-size: medium;
}

.audio-section-title {
    margin-bottom: 0px;
}

.audio-section-play-btn {
    display: contents;
    cursor: pointer;
}

.audio-section-play-btn>img {
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.song-names {
    /*     color: var(--text-color);
    font-weight: bold;
    font-size: x-large; */
    display: flex;
    align-items: center;
}

.audio-section-wrapper {
    /*     margin-left: auto;
    margin-right: auto; */
    margin-bottom: 0px;
    margin-top: 40px;
}

.audio-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* margin-left: 20vw; *
}

.audio-section-image {
    /*     margin-left: auto;
    margin-right: auto; */
    margin: 20px 20px 20px 20px;
}

.audio-section-songs {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 360px;
    /*     margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px; */
}

.audio-section-row {
    display: flex;
    flex-direction: row;
}

.audio-section-image>img {
    max-width: min(80vw, 400px);
}

@media only screen and (min-width: 1100px) {
    .audio-section-play-btn>img {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .audio-section-wrapper {
        /*     margin-left: auto;
    margin-right: auto; */
        margin-bottom: 80px;
    }

    .audio-section-songs {
        padding-left: 40px;
    }

}
</style>
