<template>
    <div>
        <div class="footer-section-wrapper">
            <div class=" footer-section-nomargin paragraph-custom" style="display:flex; margin-bottom:20px;">
                UNTERSTÜTZT von
            </div>
        </div>
        <div class="footer-section-wrapper-nomargin">
            <div class="footer-section-nomargin paragraph-custom" style="display:flex; flex-flow: wrap;">
                <div>
                    <img :src="logoKantonZuerich">
                </div>
                <div style="display: flex; align-items: center;">
                    <img :src="logoCassinelli">
                </div>
            </div>
        </div>
        <div class="footer-section-wrapper">
            <div class="footer-section paragraph-custom">
                <div class="footer-line">Don Friso Websiite</div>
                <div class="footer-line">2024</div>
                <div class="footer-line">Design: kombro</div>
                <div class="footer-line">Header-Foto: Damian Lenherr</div>
                <div class="footer-line">Programmierung: Don Friso</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterSection',
    data() {
        return {
            innerWidth: window.innerWidth,
            logoCassinelli: require("../assets/graphics/support/cassinelli.jpg"),
            logoKantonZuerich: require("../assets/graphics/support/kanton_zuerich_cut.jpg"),
        };
    },
    props: {
    },
    mounted() {
        window.addEventListener("resize", this.screenSizeChanged);
    },
    unmounted() {
        window.removeEventListener("resize", this.screenSizeChanged);
    },
    methods: {
        screenSizeChanged() {
            this.innerWidth = window.innerWidth
        }
    }
}
</script>

<style scoped>
.footer-section-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.footer-section-wrapper-nomargin {
    display: flex;
    justify-content: center;
}

.footer-section {
    width: min(80vw, 600px);
    margin-top: 100px;
    margin-bottom: 20px;
    text-align: left;
}

.footer-section-nomargin {
    width: min(80vw, 600px);
    text-align: left;
}

.paragraph-custom {
    font-weight: bold;
    font-size: small;
    text-transform: uppercase;
    color: white;
}

.footer-section-nomargin>div {
    width: 300px;
}

.footer-section-nomargin>div>img {
    width: 100%
}
</style>
