<template>
    <div id="social-buttons">
        <div class="left-social-buttons">
            <a href="https://open.spotify.com/artist/2MiLAbLYXL6FHO0BuRjoWN" target="_blank">
                <img v-if="innerWidth < 1100" class="social-button mobile-button btn-friso"
                    src="@/assets/graphics/buttons/spotify_mobile.svg" />
                <img v-else class="social-button desktop-button btn-friso"
                    src="@/assets/graphics/buttons/spotify_desktop.svg" />
            </a>
            <a href="https://www.instagram.com/donfriso/" target="_blank">
                <img v-if="innerWidth < 1100" class="social-button mobile-button btn-friso"
                    src="@/assets/graphics/buttons/insta_mobile.svg" />
                <img v-else class="social-button desktop-button btn-friso"
                    src="@/assets/graphics/buttons/insta_desktop.svg" />
            </a>
            <a href="https://www.youtube.com/channel/UCxvQSAMiy3ark7_oMpQc-7Q" target="_blank">
                <img v-if="innerWidth < 1100" class="social-button mobile-button btn-friso"
                    src="@/assets/graphics/buttons/youtube_mobile.svg" />
                <img v-else class="social-button desktop-button btn-friso"
                    src="@/assets/graphics/buttons/youtube_desktop.svg" />
            </a>
        </div>
        <div class="right-social-buttons">
            <a href="https://tr.ee/gvqoUHdcaC"  :target="linkTarget">
                <img v-if="innerWidth < 1100" class="social-button mobile-button btn-friso"
                    src="@/assets/graphics/buttons/play_mobile.svg" />
                <img v-else class="social-button desktop-button btn-friso"
                    src="@/assets/graphics/buttons/play_desktop.svg" />
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SocialButtons',
    data() {
        return {
            innerWidth: window.innerWidth,
            linkTarget: window.innerWidth > 768 ? '_blank' : '_self',
            menuOpen: false
        };
    },
    props: {
    },
    mounted() {
        window.addEventListener("resize", this.screenSizeChanged);
    },
    unmounted() {
        window.removeEventListener("resize", this.screenSizeChanged);
    },
    methods: {
        screenSizeChanged() {
            this.innerWidth = window.innerWidth
            this.linkTarget = window.innerWidth > 768 ? '_blank' : '_self';
        },
        startPlayer() {
            this.$store.commit('audioIsNotYetLoaded')
            this.$store.commit('openPlayer')
            this.$store.commit('setCurrentSongIdx', 4)
            this.$store.commit('startPlaying')
            document.getElementById("av-tag").load()
            document.getElementById("av-tag").play()
        }
    }
}
</script>

<style scoped>
#social-buttons {
    display: flex;
    justify-content: space-between;
}

.desktop-button {
    margin-left: 20px;
    margin-right: 20px;
}

.mobile-button {}

.social-button {
    cursor: pointer;
}

.social-right-buttons {
    position: sticky;
}

@media only screen and (min-width: 450px) and (max-width: 1100px) {
    #social-buttons {
        margin-left: 30px;
        margin-right: 30px;
    }
}

@media only screen and (min-width: 1100px) {
    #social-buttons {
        /* max-width: 1400px;
        margin-left: auto;
        margin-right: auto; */
    }
}
</style>
