import { createStore } from 'vuex'
import {
    allSongs
} from "../data/data.js";
// Create a new store instance.
const maxIdx = allSongs.length - 1;
const store = createStore({
    state() {
        return {
            currentSongIdx: 10,
            currentLyricsIdx: 6,
            showPlayer: false,
            playerIsPlaying: false,
            audioIsLoaded: true,
        }
    },
    mutations: {
        initAudioPlayer(state) {
            state.av = document.getElementById("av-tag");
            state.playTime = document.getElementsByClassName("play-time")[0];
            state.playBtn = document.getElementsByClassName("play-btn")[0];
            state.curTime = document.getElementById("cur-time");
            state.volume = document.getElementById("volume");
            state.speaker = document.getElementById("speaker");
        },
        incrementCurrentSongIdx(state) {
            if (state.currentSongIdx < maxIdx) { state.currentSongIdx++ }
            else { state.currentSongIdx = 0 }
        },
        decrementCurrentSongIdx(state) {
            if (state.currentSongIdx > 0) { state.currentSongIdx-- }
            else { state.currentSongIdx = maxIdx }
        },
        setCurrentSongIdx(state, newCurrentSongIdx) {
            state.currentSongIdx = newCurrentSongIdx
            console.log("currentSongIdx: ", state.currentSongIdx)
        },
        togglePlayer(state) {
            state.showPlayer = !state.showPlayer
        },
        openPlayer(state) {
            state.showPlayer = true
        },
        closePlayer(state) {
            state.showPlayer = false
        },
        startPlaying(state) {
            state.playerIsPlaying = true
        },
        stopPlaying(state) {
            state.playerIsPlaying = false
        },
        audioIsLoaded(state) {
            state.audioIsLoaded = true
            console.log("audio is loaded")
        },
        audioIsNotYetLoaded(state) {
            state.audioIsLoaded = false
            console.log("audio is not yet loaded")
        },
        incrementCurrentLyricsIdx(state) {
            if (state.currentLyricsIdx < maxIdx) { state.currentLyricsIdx++ }
            else { state.currentLyricsIdx = 0 }
        },
        decrementCurrentLyricsIdx(state) {
            if (state.currentLyricsIdx > 0) { state.currentLyricsIdx-- }
            else { state.currentLyricsIdx = maxIdx }
        },
        setCurrentLyricsIdx(state, newCurrentLyricsIdx) {
            state.currentLyricsIdx = newCurrentLyricsIdx
            document.getElementById("lyricsScrollSection").scrollTop = 0;
        },
    },
    getters: {
        getPlayerIsPlaying(state) {
            return state.playerIsPlaying
        }
    }
})

export default store