<template>
    <div class="intro-text-wrapper">
        <p class="paragraph-friso" :style="{ textTransform: 'none', marginBottom: '60px' }">
            Alternative Mundart
            auf wuchtigen Beats:
            Ein neues Level an Tiefe.
        </p>
        <p class="paragraph-friso" :style="{ fontStyle: 'italic', textTransform: 'none', marginBottom: '60px' }">«Bis
            zum
            Anschlag
            mit Energie vollgepumpt!»
        </p>

    </div>
</template>

<script>
export default {
    name: 'DescriptionText',
    data() {
        return {
            innerWidth: window.innerWidth,
            menuOpen: false
        };
    },
    props: {
    },
    mounted() {
        window.addEventListener("resize", this.screenSizeChanged);
    },
    unmounted() {
        window.removeEventListener("resize", this.screenSizeChanged);
    },
    methods: {
        screenSizeChanged() {
            this.innerWidth = window.innerWidth
        }
    }
}
</script>

<style scoped>
.intro-text-wrapper {
    width: min(80vw, 400px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 120px;
    margin-bottom: 120px;
}
</style>
