<template>
    <audio ref="player" src="https://dl.dropbox.com/s/v36drp8j1na0h8k/5_Perfekt.wav" controls />
</template>

<!-- https://dl.dropbox.com/s/v36drp8j1na0h8k/5_Perfekt.wav



This should work. You have to follow the below steps:

    Create a share link to the file then change the www in the link to dl. Use that new link in your embed code.

    Remove the end of the link

e.g. if you have:

https://www.dropbox.com/s/bt44iw272m6ga04/O%20Botic%C3%A1rio%20-%20Dia%20dos%20Pais%20novo%20perfume...

You have to change the first bold part and remove the second:

https://dl.dropbox.com/s/bt44iw272m6ga04/O%20Botic%C3%A1rio%20-%20Dia%20dos%20Pais%20novo%20perfume%...
 -->

<script >
/* import { ref } from 'vue'
import { useAVBars } from 'vue-audio-visual'

const player = ref(null)
const canvas = ref(null)

// composable function useAVBars
useAVBars(player, canvas, { src: "https://dl.dropbox.com/s/v36drp8j1na0h8k/5_Perfekt.wav", canvHeight: 40, canvWidth: 200, barColor: 'green' })
 */
//https://www.npmjs.com/package/vue-audio-visual
export default {
    name: 'AudioPlayer',
    data() {
        return {
            innerWidth: window.innerWidth,
            menuOpen: false
        };
    },
    props: {
    },
    mounted() {
        window.addEventListener("resize", this.screenSizeChanged);
    },
    unmounted() {
        window.removeEventListener("resize", this.screenSizeChanged);
    },
    methods: {
        screenSizeChanged() {
            this.innerWidth = window.innerWidth
        }
    }
}
</script>

<style scoped>
audio::-webkit-media-controls-panel {
    background-color: #56AEFF;
}

audio::moz-webkit-media-controls-panel {
    background-color: green;
}
</style>
