<template>
    <div
        :class="$store.state.showPlayer ? 'player-section paragraph-friso-black visible' : 'player-section paragraph-friso-black hidden'">
        <!-- <div class="cancel-btn"></div> -->
        <div class="player-description">
            <a href="#texte" target="_top" class="lyrics-btn"
                @click="$store.commit('setCurrentLyricsIdx', $store.state.currentSongIdx)"> <span
                    class=" material-symbols-outlined">
                    article
                </span></a>
            <button class="skip-btn" @click="previousBtnClicked()"> <span class="material-symbols-outlined">
                    arrow_back_ios
                </span></button>
            <div class="cur-song-name ">{{ allSongs[$store.state.currentSongIdx].title }}</div>
            <button class="skip-btn" @click="nextBtnClicked()"><span class="material-symbols-outlined">
                    arrow_forward_ios
                </span></button>
            <button class="cancel-btn" @click="closePlayer()"> <span class="material-symbols-outlined">
                    cancel
                </span></button>
        </div>
        <div v-bind:class="{
            'player': true,
            'second-line-in-audio-player': true,
            'hidden-load': !$store.state.audioIsLoaded
        }">
            <audio id="av-tag" controls>
                <source :src="allSongs[$store.state.currentSongIdx].file" type="audio/wav">
                Your browser does not support the audio element.
            </audio>
            <button class="play-btn"><span v-if="$store.state.playerIsPlaying" class=" material-symbols-outlined">
                    pause </span>
                <span v-if="!$store.state.playerIsPlaying" class=" material-symbols-outlined"> play_arrow
                </span></button>
            <div class="play-time ">0:00</div>
            <input id="cur-time" type="range" min="0" max="10" step="0">
            <div id="speaker" style="margin-left: 10px; margin-right: 10px;">
                <span class="material-symbols-outlined">
                    signal_cellular_0_bar
                </span>
            </div>
            <input id="volume" type="range" min="0" max="10">
        </div>
        <div v-bind:class="{
            'loading-info': true,
            'second-line-in-audio-player': true,
            'hidden-load': $store.state.audioIsLoaded
        }">
            <p>Song ladt... zzz...</p>
        </div>
    </div>
</template>

<script >
import {
    allSongs
} from "../data/data.js";
/* 
https://fonts.google.com/icons?icon.query=previous
equalizer
https://www.brainbeast.best/customizable-audio-player-for-webpages/
more on audio loading
https://stackoverflow.com/questions/9337300/html5-audio-load-event
*/

export default {
    name: 'AudioPlayerCustom',
    data() {
        return {
            innerWidth: window.innerWidth,
            menuOpen: false,
            allSongs: allSongs,
        };
    },
    mounted() {
        this.$store.commit('initAudioPlayer')
        this.$el.addEventListener('click', this.onClick);
    },
    unmounted() {
        this.$el.removeEventListener('click', this.onClick);
    },
    //computed: {
    //initialPlayIcon() { return this.$store.state.playerIsPlaying ? '<span class=" material-symbols-outlined" > pause </span > ' : '<span class=" material-symbols-outlined" > play_arrow </span > ' }
    //},
    methods: {
        previousBtnClicked() {
            previousSong()
        },
        nextBtnClicked() {
            nextSong()
        },
        closePlayer() {
            this.$store.commit('stopPlaying')
            document.getElementById("av-tag").pause()
            this.$store.commit('closePlayer')
        },
    }
}
import store from '../store/store.js'
//import scrollToId from '../utils/utils.js'
function nextSong() {
    store.commit('audioIsNotYetLoaded')
    if (store.state.playerIsPlaying) {
        store.commit('incrementCurrentSongIdx')
        document.getElementById("av-tag").load()
        document.getElementById("av-tag").play()
    }
    else {
        store.commit('incrementCurrentSongIdx')
        document.getElementById("av-tag").load()
    }
}
function previousSong() {
    store.commit('audioIsNotYetLoaded')
    if (store.state.playerIsPlaying) {
        store.commit('decrementCurrentSongIdx')
        document.getElementById("av-tag").load()
        document.getElementById("av-tag").play()
    }
    else {
        store.commit('decrementCurrentSongIdx')
        document.getElementById("av-tag").load()
    }
}
document.addEventListener("DOMContentLoaded", () => {
    //dom objects of colntrol elements
    var av = document.getElementById("av-tag");
    var playTime = document.getElementsByClassName("play-time")[0];
    var playBtn = document.getElementsByClassName("play-btn")[0];
    var curTime = document.getElementById("cur-time");
    //var volume = document.getElementById("volume");
    //var speaker = document.getElementById("speaker");
    //var nextBtn = document.getElementById("next-btn");
    //var previousBtn = document.getElementById("previous-btn");
    //variable to check if player is playing or not

    av.onloadedmetadata = function () {
        curTime.max = av.duration;
    };

    //function of displaying current time
    store.state.av.ontimeupdate = function () {

        var sec_num = store.state.av.currentTime;
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
        seconds = Math.round(seconds);

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) { seconds = "0" + seconds; } playTime.innerHTML = minutes + ':' + seconds;
        if (store.getters.getPlayerIsPlaying) curTime.value = av.currentTime;
        if (av.currentTime >= curTime.max) {
            nextSong()
        }
    };
    //function to control volume
    /*     volume.onchange = function () {
            av.volume = volume.value / 10;
        }; */
    store.state.volume.oninput = function () {
        store.state.av.volume = store.state.volume.value / 10;
    };
    //function to control playing time
    /*     curTime.onchange = function () {
            av.pause();
            av.currentTime = curTime.value;
            av.play();
        }; */
    store.state.curTime.oninput = function () {
        /*      if (isPlaying) {
                 var wasPlaying = true;
                  av.pause();
    }*/
        store.state.av.currentTime = store.state.curTime.value;
        /*  if (wasPlaying) {
             av.play();
         } */
    };
    //function to switch off or switch on volume by click on speacker
    store.state.speaker.onclick = function () {
        if (store.state.volume.value == 0) {
            store.state.volume.value = 10; store.state.av.volume = 1;
        } else {
            store.state.volume.value = 0; store.state.av.volume = 0;
        }
    };
    //function to control play/pause and display play button symbol
    playBtn.addEventListener("click", () => {
        if (store.getters.getPlayerIsPlaying) {
            store.state.av.pause();
            /* av.load(); */
            store.commit('stopPlaying')
            //store.state.playBtn.innerHTML = '<span class=" material-symbols-outlined" > play_arrow </span > ';
        }
        else {
            /* av.load() */
            store.state.av.play();
            store.commit('startPlaying')
            //store.state.playBtn.innerHTML = '<span class=" material-symbols-outlined" > pause </span > '
        }

    });
    av.oncanplaythrough = function () {
        console.log("can now play")
        store.commit('audioIsLoaded')
    }
});

</script>

<style scoped>
.player {
    padding-bottom: 10px;
}

.player * {
    display: inline-block;
    vertical-align: middle;
    background: none;
    border: none;
    /* font-size: x-large; */
}

.player-description * {
    display: inline-block;
    vertical-align: middle;
    background: none;
    border: none;
    /* font-size: x-large; */
}

.cancel-btn * {
    display: inline-block;
    vertical-align: middle;
    background: none;
    border: none;
    /* font-size: x-large; */
}

.player audio {
    display: none;
}

.player-section {
    /* border: 2px solid #600a6f; */
    /* border: 2px solid white; */
    background: var(--achterbahn-color);
    max-width: min(400px, 100vw);
    /* padding: 12px; */
    /* border-radius: 20px;*/
    position: sticky;
    bottom: 0px;
    margin-left: auto;
    border-top-left-radius: 15px;
    border-left: 5px solid black;
    border-top: 5px solid black;
    border-top-right-radius: 15px;
    border-right: 5px solid black;
    height: 100px;
    padding-top: 5px;
    color: black;
}

.player-description {
    margin-top: 10px;
    margin-bottom: 10px;
}

.player input[type="range"] {
    -webkit-appearance: none;
    height: 5px;
    border-radius: 5px;
    background: black;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.player input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
}

.player input[type="range"]::-moz-range-thumb {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
}

#volume {
    width: 40px;
}

.play-btn {
    width: 40px;
    /* needed so that play / pause does not resize */
    font-size: xx-large;
    /* color: white; */
    cursor: pointer;
}

.play-btn>span {
    font-size: 30px;
}

.play-time {
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
}

#cur-time {
    width: 70px;
}

.skip-btn {
    cursor: pointer;
}

#speaker {
    cursor: pointer;
}

.cancel-btn {
    position: absolute;
    right: 0;
    margin-right: 10px;
    cursor: pointer;
}

.lyrics-btn {
    position: absolute;
    left: 0;
    top: 15px;
    margin-left: 10px;
    cursor: pointer;
    /* display: inline-block; */
    /* vertical-align: middle; */
    background: none;
    border: none;
    /* display: contents; */
    /* font-size: x-large; */
}

.hidden {
    max-height: 0px;
    /* visibility: hidden; */
    opacity: 0;
    /* transition: max-height 2s ease, opacity 2s ease; */
    transition: all 1s;

}

.hidden-load {
    height: 0px;
    display: none;
}

.visible {
    max-height: 200px;
    /*  visibility: visible; */
    opacity: 1;
    /* transition: max-height 2s ease, opacity 2s ease; */
    transition: all 1s;
}

.cur-song-name {
    max-width: 80px;
    /* overflow: scroll; */
    overflow-y: hidden;
    white-space: nowrap;
}

@media only screen and (min-width: 350px) {
    .cur-song-name {
        max-width: max-content;
    }
}

.loading-info {
    position: relative;
    bottom: 0px;
    text-align: center;
    width: 100%;
}

.second-line-in-audio-player {
    height: 35px;
}

a {
    color: black;
}

span {
    color: black;
}
</style>
