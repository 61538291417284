<template>
    <div class="listen-buttons-section">
        <div class="listen-buttons-section-title">
            <h2 class="paragraph-friso">
                Friso lose uf:
            </h2>
        </div>
        <div id="social-buttons">
            <div class="middle-social-buttons">
                <a href="https://open.spotify.com/artist/2MiLAbLYXL6FHO0BuRjoWN" target="_blank">
                    <img v-if="innerWidth < 1100" class="social-button mobile-button btn-friso"
                        src="@/assets/graphics/buttons/spotify_mobile.svg" />
                    <img v-else class="social-button desktop-button btn-friso"
                        src="@/assets/graphics/buttons/spotify_desktop.svg" />
                </a>
                <a href="https://www.youtube.com/channel/UCxvQSAMiy3ark7_oMpQc-7Q" target="_blank">
                    <img v-if="innerWidth < 1100" class="social-button mobile-button btn-friso"
                        src="@/assets/graphics/buttons/youtube_mobile.svg" />
                    <img v-else class="social-button desktop-button btn-friso"
                        src="@/assets/graphics/buttons/youtube_desktop.svg" />
                </a>
                <a href="https://donfriso.bandcamp.com/" target="_blank">
                    <img v-if="innerWidth < 1100" class="social-button mobile-button btn-friso"
                        src="@/assets/graphics/buttons/bandcamp_mobile.svg" />
                    <img v-else class="social-button desktop-button btn-friso"
                        src="@/assets/graphics/buttons/bandcamp_desktop.svg" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ListenButtons',
    data() {
        return {
            innerWidth: window.innerWidth,
            menuOpen: false
        };
    },
    props: {
    },
    mounted() {
        window.addEventListener("resize", this.screenSizeChanged);
    },
    unmounted() {
        window.removeEventListener("resize", this.screenSizeChanged);
    },
    methods: {
        screenSizeChanged() {
            this.innerWidth = window.innerWidth
        },
    }
}
</script>

<style scoped>
.listen-buttons-section {
    margin-bottom: 30px;
}
.listen-buttons-section-title {
    margin-top: 70px;
    margin-bottom: 30px;
}

#social-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.desktop-button {
    margin-left: 20px;
    margin-right: 20px;
}

.mobile-button {}

.social-button {
    cursor: pointer;
}



@media only screen and (min-width: 450px) and (max-width: 1100px) {
    #social-buttons {
        margin-left: 30px;
        margin-right: 30px;
    }
}

@media only screen and (min-width: 1100px) {
    #social-buttons {
        /* max-width: 1400px;
        margin-left: auto;
        margin-right: auto; */
    }
}
</style>
