<template>
    <div>
        <h1>Kontakt</h1>
        <div v-if="showLoadingBar" class="spinning-logo-wrapper">
            <span class="helper"></span>
            <img class="spinning-logo" src="@/assets/graphics/don_friso_logo.png" />
        </div>
        <div v-if="showSubmitNote == true" style="color: #ffffff;" class="submitted-note">
            <p class="paragraph-friso">
                Härzleche Dank, du ghörsch gli vo mer.
            </p>
        </div>
        <div v-if="!showLoadingBar && !showSubmitNote" class="contact-form-wrapper">
            <form @submit="checkAndSend">
                <div class="contact-form">
                    <div class="first-line paragraph-friso">
                        <div>
                            <a href="tel:+41 79 530 48 72">079 530 48 72</a>
                        </div>
                        <div><a href="mailto:don@don-friso.ch">don@don-friso.ch</a>
                        </div>
                    </div>
                    <label class="paragraph-friso">Name</label>
                    <input type="text" v-model="formInputs.name.value" :name="formInputs.name.label"
                        :placeholder="formInputs.name.placeholder">
                    <label class="paragraph-friso">Email</label>
                    <input type="email" v-model="formInputs.email.value" :name="formInputs.email.label"
                        :placeholder="formInputs.email.placeholder">
                    <label class="paragraph-friso">Tel</label>
                    <input type="tel" v-model="formInputs.tel.value" :name="formInputs.tel.label"
                        :placeholder="formInputs.tel.placeholder">
                    <label class="paragraph-friso">Betreff</label>
                    <input type="text" v-model="formInputs.subject.value" :name="formInputs.subject.label"
                        :placeholder="formInputs.subject.placeholder">
                    <label class="paragraph-friso">Nachricht</label>
                    <textarea v-model="formInputs.msg.value" :name="formInputs.msg.label"
                        :placeholder="formInputs.msg.placeholder"></textarea>
                    <label class="paragraph-friso">Friso News (ca. alle 3 Monate)</label>
                    <input type="checkbox" v-model="formInputs.frisoNews.value">
                    <label class="paragraph-friso">Wie möchtest du die Friso News serviert?</label>
                    <input type="text" v-model="formInputs.frisoNewsText.value" :name="formInputs.frisoNewsText.label"
                        :placeholder="formInputs.frisoNewsText.placeholder">
                    <div style="color: #9e1e1d;">
                        <div class="error-wrapper">
                            <p v-if="errors.length" class="error-field">
                            <ul>
                                <li v-for="error in errors" :key="error">{{ error }}</li>
                            </ul>
                            </p>
                        </div>
                        <div v-if="submitError == true" style="color: #9e1e1d;" class="error-note">
                            <p>
                                ERROR
                            </p>
                        </div>
                    </div>
                    <img src="https://www.transparentpng.com/download/circle-png/5CC7Ci-circle-png-picture.png"
                        class="mylogo" width="180px" />
                    <input class="submitButton" type="submit" value="Wuuusch!">
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
    name: 'KontaktForm',
    components: {},
    data() {
        return {
            fullScreenLoadingBar: true,
            showLoadingBar: false,
            showSubmitNote: false,
            submitError: false,
            errors: [],
            formInputs: {
                name: {
                    label: "Vor- und Nachname",
                    value: null,
                    liClass: "fa-user",
                    type: "name",
                    placeholder: "oder Pseudonym..."
                },
                email: {
                    label: "Email",
                    value: null,
                    liClass: "fa-envelope",
                    type: "email",
                    placeholder: "Falls du per Email plaudern möchtest..."
                },
                tel: {
                    label: "Tel",
                    value: null,
                    liClass: "fa-phone",
                    type: "phone",
                    placeholder: "...und / oder per Telefon"
                },
                subject: {
                    label: "Betreff",
                    value: null,
                    liClass: null,
                    type: "text",
                    placeholder: "Was steht an?"
                },
                msg: {
                    label: "Persönliche Nachricht",
                    value: null,
                    liClass: null,
                    type: "text",
                    placeholder: "Schreib drauf los!"
                },
                frisoNews: {
                    label: "FrisoNews",
                    value: null,
                    liClass: null,
                    type: "checkbox",
                },
                frisoNewsText: {
                    label: "FrisoNewsText",
                    value: null,
                    liClass: null,
                    type: "text",
                    placeholder: "Email, Whatsapp, Telegram, Rauchzeichen..."
                }
            },

        };
    },
    props: {
    },
    mounted() {
        window.addEventListener("resize", this.screenSizeChanged);
    },
    unmounted() {
        window.removeEventListener("resize", this.screenSizeChanged);
    },
    methods: {
        debug() {
            console.log("forInputs: ", this.formInputs)
        },
        screenSizeChanged() {
            this.innerWidth = window.innerWidth
        },
        checkAndSend: function (e) {
            const regexExpEmail =
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
            //const regexExpPhone = /^\+?/;
            this.errors = [];

            if (!this.formInputs.name.value) {
                this.errors.push('En Name zom Kontaktiere wär cool. Chasch aber au eine erfende!');
            }

            if (!regexExpEmail.test(this.formInputs.email.value) && !this.formInputs.tel.value) {
                this.errors.push('Dini Email oder Tel-Nomere brüchti zom dech kontaktiere');
                if (!regexExpEmail.test(this.formInputs.email.value)) {
                    this.errors.push('Email esch ongöltig')
                }
                if (!this.formInputs.tel.value) {
                    this.errors.push('Kei Telefonnommere agäh')
                }
            }
            e.preventDefault();
            if (this.errors.length === 0) {
                this.sendEmail();
            }
        },
        sendEmail() {
            this.showLoadingBar = true;
            let body = {
                to_email: "gab.voirol@gmail.com",
                from_name: this.formInputs.name.value,
                from_email: this.formInputs.email.value,
                from_tel: this.formInputs.tel.value,
                subject: this.formInputs.subject.value,
                msg: this.formInputs.msg.value,
                frisoNews: this.formInputs.frisoNews.value,
                frisoNewsText: this.formInputs.frisoNewsText.value
            }
            console.log("body: ", body)
            emailjs
                .send(
                    "zoho",
                    "DF",
                    body,
                    "user_WmNF2VkVjJvS14IHLywyB"
                )
                .then(
                    (result) => {
                        console.log("success: ", result.status, result.text);
                        this.showSubmitNote = true;
                        this.showLoadingBar = false;
                        this.clearForm();
                        this.closeSubmitInfoAfterDelay();
                    },
                    (error) => {
                        console.log("error: ", error);
                        this.submitError = true;
                        this.showLoadingBar = false;
                    }
                );

        },
        clearForm() {
            for (var key in this.formInputs) {
                this.formInputs[key].value = null;
            }
            for (key in this.$store.state.bankListingCmps) {
                this.$store.state.bankListingCmps[key].checked = false;
            }
        },
        closeSubmitInfoAfterDelay() {
            setTimeout(() => {
                this.showSubmitNote = false;
            }, 6000);
        },
    }
}
</script>

<style scoped>
.mylogo {
    animation: rotation 3s infinite linear;
}

a {
    text-decoration: none;
    color: white;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

input:focus::placeholder {
    color: transparent;
}

.contact-form-wrapper {
    display: flex;
    justify-content: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: min(80vw, 600px);
}

label {
    text-align: left;
}

.error-field {
    text-align: left;
}

.first-line {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 40px;
}

.first-line>div {
    height: 20px;
}

.spinning-logo-wrapper {
    height: min(80vw, 300px);
    text-align: center;
}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.spinning-logo {
    width: min(80vw, 300px);
    height: calc(min(80vw, 300px)*0.44);
    animation: rotation 7s infinite linear;
    vertical-align: middle;
}



input,
textarea {
    margin-top: 5px;
    margin-bottom: 15px;
    border-radius: 15px;
    padding-left: 30px;
    height: 30px;
    text-transform: uppercase;
    border: none;
    text-transform: none;
}

textarea {
    padding-top: 10px;
    height: 100px;
    font-family: 'Space Grotesk', "Helvetica", "Roboto", "Arial", sans-serif;
    color: #000000;
}

.submitButton {
    background-color: var(--achterbahn-color);
    border: none;
    padding-left: 0px;
    cursor: pointer;
}

@media only screen and (min-width: 1100px) {
    .contact-form {
        width: min(80vw, 600px);
    }
}

@media only screen and (min-width: 520px) {

    .first-line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
</style>
