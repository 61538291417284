import { createApp } from 'vue'
import App from './App.vue'
import { AVPlugin } from 'vue-audio-visual'
import store from './store/store'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import { GlobalEvents } from 'vue-global-events'

/* 
import firebase from 'firebase'const firebaseConfig = {
    apiKey: "AIzaSyC3GHfW_MYeoNr6BFc9qwyy92HZZsgYaWI",
    authDomain: "don-friso-homepage.firebaseapp.com",
    projectId: "don-friso-homepage",
    storageBucket: "don-friso-homepage.appspot.com",
    messagingSenderId: "17583317278",
    appId: "1:17583317278:web:5b2977f7b87e442b4d690f",
    measurementId: "G-S6VHKDS00H"
};
firebase.initializeApp(firebaseConfig); */

const app = createApp(App)
app.use(AVPlugin)
app.use(store)
app.mount('#app')
app.use(mdiVue, {
    icons: mdijs
})
app.component('GlobalEvents', GlobalEvents)